import React, { useEffect } from "react";
import TextFieldAll from "./TextFieldAll.jsx";

const LocationComponent = ({
  id,
  label,
  value,
  error,
  loadingInput,
  setValue,
  setError,
}) => {
  useEffect(() => {
    // Проверяем, поддерживает ли браузер геолокацию
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;

          // Используем API для обратного геокодирования
          fetch(
            `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=ru`
          )
            .then((response) => response.json())
            .then((data) => {
              if (data.city) {
                setValue((prevValues) => ({
                  ...prevValues,
                  [id]: data.city,
                }));
                setError((prevErrors) => ({
                  ...prevErrors,
                  [id]: data.city === "",
                }));
              }
            })
            .catch((err) => console.error(err));
        },
        (error) => {
          console.error("Ошибка получения местоположения:", error);
        }
      );
    } else {
      console.error("Геолокация не поддерживается в этом браузере.");
    }
  }, [id, setError, setValue]);

  return (
    <TextFieldAll
      id={id}
      label={label}
      value={value}
      error={error}
      loadingInput={loadingInput}
      setValue={setValue}
      setError={setError}
    />
  );
};

export default LocationComponent;

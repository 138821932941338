import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./pages/homepage.jsx";
import GPT from "./pages/gpt.jsx";
import PaymentStatus from "./pages/paymentStatus.jsx";
import Logon from "./pages/logon.jsx";
import { useState } from "react";

function App() {
  const [paymentId, setPaymentId] = useState("");
  console.log (paymentId);

  return (
    <Router>
      <Routes>
        {/* Главная страница */}
        <Route path="/" element={<HomePage setPaymentId={setPaymentId}/>} />
        <Route path="/gpt" element={<GPT />} />
        <Route path="/payment" element={<PaymentStatus paymentId={paymentId}/>} />
        <Route path="/logon" element={<Logon/>} />
      </Routes>
    </Router>
  );
}

export default App;

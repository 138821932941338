import { Autocomplete, TextField } from "@mui/material";
import { fetchSuggestions } from "../../service/getDadata.js";
import Popper from "@mui/material/Popper";
import { styled } from "@mui/system";

const Address = ({
  id,
  suggestions,
  value,
  label,
  error,
  setValue,
  setError,
  setSuggestions,
}) => {
  const handleChange = (e, newValue) => {
    const value = e.target.value ? e.target.value : newValue;
    setValue((prevValues) => ({
      ...prevValues,
      [id]: value,
    }));
    setError((prevErrors) => ({
      ...prevErrors,
      [id]: value === "",
    }));

    fetchSuggestions(value, setSuggestions);
  };

  const StyledPopper = styled(Popper)(({ theme }) => ({
    zIndex: theme.zIndex.modal, // Контролируем z-index, чтобы окно было выше других элементов
  }));

  const CustomPopper = (props) => {
    return (
      <StyledPopper
        {...props}
        placement="top-start" // Указываем что всплывающее окно всегда отображается сверху
        modifiers={[
          {
            name: "flip",
            enabled: false, // Отключаем переворот окна (он может по умолчанию переворачивать вниз)
          },
          {
            name: "preventOverflow",
            options: {
              altBoundary: true,
              tether: true, // Опция, чтобы окно оставалось на экране
            },
          },
        ]}
      />
    );
  };

  return (
    <Autocomplete
      freeSolo
      options={suggestions}
      PopperComponent={CustomPopper}
      filterOptions={(x) => x}
      inputValue={value[id] || ""}
      fullWidth
      onInputChange={(event, newValue) => handleChange(event, newValue)}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          fullWidth
          error={!!error[id]}
          helperText={!!error[id] ? "Заполните поле" : ""}
        />
      )}
    />
  );
};

export default Address;

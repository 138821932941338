import { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Paper,
  DialogActions,
  Button,
  Box,
  CircularProgress,
  IconButton,
  Backdrop,
} from "@mui/material";
import getPayments from "../../service/getPayments";
import CloseIcon from "@mui/icons-material/Close";

const ConDialogDoc = ({
  googleDocsUrl,
  setUrlDoc,
  selectedContract,
  setPaymentId,
  orderId,
  setLoading,
  loading,
}) => {
  const [count, setCount] = useState(0);
  const [iframeKey, setIframeKey] = useState(0);

  const handleClose = () => {
    setUrlDoc("");
  };

  const uniqueUrl = `${googleDocsUrl}`;

  const handleIframeLoad = () => {
    setCount((prevCount) => prevCount + 1);
    setLoading(false);
  };

  useEffect(() => {
    let intervalId;
    // Устанавливаем интервал обновления iframe каждые 2 секунды, если iframe не загружен
    if (count === 0) {
      intervalId = setInterval(() => {
        setIframeKey((prevKey) => prevKey + 1); // Перезагружаем iframe путем изменения ключа
      }, 5000);
    }

    // Очищаем интервал после загрузки
    if (count > 0) {
      clearInterval(intervalId);
    }

    // Чистим интервал при размонтировании компонента
    return () => clearInterval(intervalId);
  }, [count]);

  return (
    <>
      <Backdrop open={loading} style={{ zIndex: 9999, color: "#fff" }}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog open={true} onClose={handleClose} fullWidth maxWidth="md" sx={{zIndex: 1050}}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <DialogTitle>{selectedContract}</DialogTitle>
          <DialogTitle>
            {" "}
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
        </Box>
        <DialogContent sx={{ paddingBottom: 0, paddingTop: 0 }}>
          <Paper elevation={2} sx={{ borderRadius: 1 }}>
            <iframe
              key={iframeKey}
              id="myIframe"
              src={uniqueUrl}
              title={selectedContract}
              style={{ width: "100%", border: "none", height: "80vh" }}
              onLoad={handleIframeLoad}
              allowFullScreen
            ></iframe>
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ paddingRight: "16px" }}
            autoFocus
            onClick={async () => {
              setLoading(true);
              await getPayments(orderId);
              setLoading(false);
            }}
          >
            Получить полный документ
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ConDialogDoc;

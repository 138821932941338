import {
  FormControl,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import NumberField from "./Numberfield";

const TextCheckBox = ({
  id,
  isChecked,
  label,
  value,
  error,
  numberFormat,
  innerFields,
  setIsChecked,
  setValue,
  setError,
}) => {
  //const numberFormat = typeInput.split("_")[1];

  const handleChange = (id, event) => {
    const checked = event.target.checked;
    setIsChecked(checked);
    const value = checked ? "Без залога" : null;
    innerFields.forEach((field) => {
      setValue((prevValues) => ({
        ...prevValues,
        [field.id]: value,
      }));
      setError((prevErrors) => ({
        ...prevErrors,
        [field.id]: value === "",
      }));
    });
  };

  return (
    <FormControl fullWidth>
      <FormControlLabel
        control={
          <Checkbox
            checked={isChecked}
            onChange={(event) => handleChange(id, event)}
          />
        }
        label={label}
      />

      {!isChecked &&
        innerFields.map((fields) => {
          const numberFormat =
            fields.type.includes("number") && fields.type.split("_")[1];
          return (
            <NumberField
              key={fields.id}
              id={fields.id}
              numberFormat={numberFormat}
              value={value}
              error={error}
              label={fields.label}
              setValue={setValue}
              setError={setError}
            />
          );
        })}
    </FormControl>
  );
};

export default TextCheckBox;

import { Autocomplete, TextField, Checkbox } from "@mui/material";
import { CheckBoxOutlineBlank, CheckBox } from "@mui/icons-material";

const SelectCheckBoxes = ({
  id,
  data,
  value,
  label,
  error,
  setValue,
  setError,
}) => {
  const handleChange = (e, newValue) => {
    const value = newValue;
    setValue((prevValues) => ({
      ...prevValues,
      [id]: value,
    }));
    setError((prevErrors) => ({
      ...prevErrors,
      [id]: value === "",
    }));
  };

  const icon = <CheckBoxOutlineBlank fontSize="small" />;
  const checkedIcon = <CheckBox fontSize="small" />;

  return (
    <Autocomplete
      multiple
      options={data}
      disableCloseOnSelect
      getOptionLabel={(data) => data.title}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.title}
        </li>
      )}
      value={value[id] || []}
      onChange={(event, newValue) => handleChange(event, newValue)}
      //isOptionEqualToValue={(option, value) => option.id === value.id}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          error={!!error[id]}
          fullWidth
          helperText={!!error[id] ? "Заполните поле" : ""}
        />
      )}
    />
  );
};

export default SelectCheckBoxes;

import React, { useState, useEffect } from "react";
import TypeSelect from "../components/TypeSelect/TypeSelect.js";
import ConHeader from "../components/ConHeader/ConHeader.js";
import ConPaper from "../components/ConPaper/ConPaper.js";
import { Contractors } from "../components/contractor.data.js";
import ConDataInput from "../components/ConDataInput/СonDataInput.js";
import {
  ThemeProvider,
  createTheme,
  Box,
  CssBaseline,
  GlobalStyles,
} from "@mui/material";
import generateDoc from "../service/generateDoc.js";
import ConDialogDoc from "../components/ConDialogDoc/ConDialogDoc.js";
import ModalDialog from "../components/ModalDialog/ModalDialog.js";

const HomePage = ({ setPaymentId }) => {
  const [selectedContract, setSelectedContract] = useState(""); // Состояние для значений выбора договора
  const [inputValues, setInputValues] = useState({}); // Состояние для хранения заполненных значений
  const [valuesError, setValuesError] = useState({}); // Состояние для хранения ошибок
  const [istg, setIsTg] = useState(false); // Состояние миниприложения в ТГ
  const [tg, setTg] = useState({}); // Состояние миниприложения в ТГ
  const [darkMode, setDarkMode] = useState(true); // Состояние для хранения текущей темы (светлая или тёмная)
  const [urlDoc, setUrlDoc] = useState("");
  const [orderId, setOrderId] = useState("");
  const [loading, setLoading] = useState(false);

  const googleDocsUrl = urlDoc
    ? `https://docs.google.com/gview?url=${encodeURIComponent(
        urlDoc
      )}&embedded=true`
    : "";

  useEffect(() => {
    if (window.Telegram && window.Telegram.WebApp) {
      const tg = window.Telegram.WebApp;
      setTg(tg);
      tg.ready();
      const initialTheme = tg.themeParams;
      if (!(initialTheme && Object.keys(initialTheme).length === 0)) {
        setIsTg(true);
        setDarkMode(tg.colorScheme === "dark" ? true : false);
      } else {
        const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
        setDarkMode(mediaQuery.matches);
      }
    }
  }, []);

  // Создание темы в зависимости от состояния darkMode
  const theme = createTheme({
    palette: {
      mode: darkMode ? "dark" : "light", // Выбор светлой или тёмной темы
    },
    spacing: 8,
  });

  const handleContractChange = (newContract) => {
    setSelectedContract(newContract);
  };

  const selectedContractData = Contractors.find(
    (contractor) => contractor.id === selectedContract
  );

  const handleSubmitValues = async () => {
    // Отправляем значения для обработки
    const newErrors = {};
    let haveErrors = false;

    // Проверяем, какие поля остались пустыми
    selectedContractData.data.forEach((fieldsData) => {
      fieldsData.data.forEach((field) => {
        // Проверяем, если поле не является чекбоксом и его значение пустое
        if (
          (field.type !== "checkbox" || field.type !== "dynamic") &&
          !inputValues[field.id]
        ) {
          newErrors[field.id] = true; // Если поле пустое, отмечаем его как ошибку
          haveErrors = true;
        }

        // Проверяем, если есть вложенные поля
        if (field.innerFields) {
          field.innerFields.forEach((innerField) => {
            // Проверяем, если вложенное поле не является чекбоксом и его значение пустое
            if (
              (field.type !== "checkbox" || field.type !== "dynamic") &&
              !inputValues[innerField.id]
            ) {
              newErrors[innerField.id] = true; // Если поле пустое, отмечаем его как ошибку
              haveErrors = true;
            }
          });
        }
      });
    });

    setValuesError(newErrors);
    haveErrors = false;
    if (!haveErrors) {
      try {
        setLoading(true);
        istg
          ? tg.sendData(JSON.stringify(inputValues))
          : await generateDoc(inputValues, setUrlDoc, setOrderId);
      } catch (error) {
        ModalDialog(
          "Ошибка",
          `Ошибка формирования документа /n ${error.message}`,
          "error"
        );
      }
      //setInputValues({});
      //setValuesError({});
    }
  };

  return (
    <ThemeProvider theme={theme}>
      {/* CssBaseline сбрасывает стандартные стили и применяет тему к body */}
      <CssBaseline />

      {/* Дополнительные глобальные стили для body */}
      <GlobalStyles
        styles={{
          body: {
            margin: 0, // Убираем отступы по умолчанию
            padding: 0, // Убираем внутренние отступы
            backgroundColor: theme.palette.background.default, // Применяем цвет фона из темы
            color: theme.palette.text.primary, // Применяем цвет текста из темы
            fontFamily: theme.typography.fontFamily, // Применяем шрифты из темы
          },
        }}
      />
      {!istg && (
        <>
          <ConHeader theme={theme} />
          <ConPaper theme={theme} />
        </>
      )}
      <Box>
        <TypeSelect
          selectedContract={selectedContract}
          onContractChange={handleContractChange}
          clearErrors={setValuesError}
          clearInputs={setInputValues}
        />
        {selectedContractData && (
          <ConDataInput
            selectedContractData={selectedContractData}
            inputValues={inputValues}
            setInputValues={setInputValues}
            valuesError={valuesError}
            setValuesError={setValuesError}
            handleSubmitValues={handleSubmitValues}
            loading={loading}
          />
        )}
      </Box>
      {urlDoc && (
        <ConDialogDoc
          googleDocsUrl={googleDocsUrl}
          setUrlDoc={setUrlDoc}
          setPaymentId={setPaymentId}
          selectedContract={selectedContractData.name}
          orderId={orderId}
          setLoading={setLoading}
          loading={loading}
        />
      )}
    </ThemeProvider>
  );
};

export default HomePage;

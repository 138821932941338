import React, { useState } from "react";
import Passport from "../Fields/Passport.jsx";
import DateField from "../Fields/Datefield.jsx";
import NumberField from "../Fields/Numberfield.jsx";
import SelectCheckBoxes from "../Fields/SelectCheckBoxes.jsx";
import SelectField from "../Fields/SelectField.jsx";
import TextFieldAll from "../Fields/TextFieldAll.jsx";
import TextCheckBox from "../Fields/Сheckbox.jsx";
import DynamicField from "../Fields/Dynamicfields.jsx";
import LocationComponent from "../Fields/Location.jsx";
import Address from "../Fields/Address.jsx";

const ConFieldInput = ({
  id,
  label,
  label_checkbox,
  typeInput,
  typeText,
  valueWidth,
  selectedId,
  valueId,
  data,
  value,
  setValue,
  error,
  setError,
  setLoadingInput,
  loadingInput,
  innerFields,
}) => {
  const [suggestions, setSuggestions] = useState([]);
  const [isChecked, setIsChecked] = useState(false);

  const numberFormat = typeInput.includes("number") && typeInput.split("_")[1];

  const fieldProps = {
    id: id,
    label: label,
    label_checkbox: label_checkbox,
    typeInput: typeInput,
    typeText: typeText,
    valueWidth: valueWidth,
    selectedId: selectedId,
    data: data,
    innerFields: innerFields,
    valueId: valueId,
    value: value,
    setValue: setValue,
    error: error,
    setError: setError,
    setLoadingInput: setLoadingInput,
    loadingInput: loadingInput,
    setIsChecked: setIsChecked,
    isChecked: isChecked,
    suggestions: suggestions,
    setSuggestions: setSuggestions,
    numberFormat: numberFormat,
  };

  return typeInput === "passport" ? (
    <Passport {...fieldProps} />
  ) : typeInput === "date" ? (
    <DateField {...fieldProps} />
  ) : typeInput === "address" ? (
    <Address {...fieldProps} />
  ) : typeInput.includes("number") ? (
    <NumberField {...fieldProps} />
  ) : typeInput === "select-checkboxes" ? (
    <SelectCheckBoxes {...fieldProps} />
  ) : typeInput === "select" ? (
    <SelectField {...fieldProps} />
  ) : typeInput === "checkbox" ? (
    <TextCheckBox {...fieldProps} />
  ) : typeInput === "dynamic" ? (
    <DynamicField {...fieldProps} />
  ) : typeInput === "location" ? (
    <LocationComponent {...fieldProps} />
  ) : (
    <TextFieldAll {...fieldProps} />
  );
};

export default ConFieldInput;

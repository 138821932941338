export const Contractors = [
  {
    id: 1,
    name: "Договор аренды",
    data: [
      {
        block: "Укажите сведения о собственнике и арендаторе:",
        data: [
          {
            id: "FIOSobstvk",
            type: "text",
            label: "ФИО собственника",
            width: "300px",
          },
          {
            id: "paspsobs",
            type: "passport",
            label: "Паспорт собственника",
            width: "300px",
          },
          {
            id: "adrsobstvk",
            type: "address",
            label: "Адрес регистрации собственника",
            width: "300px",
          },
          {
            id: "drsobs",
            type: "date",
            label: "Дата рождения собственника",
            width: "300px",
          },
          {
            id: "telsobs",
            type: "number_10",
            label: "Номер телефона собственника",
            width: "300px",
          },
          {
            id: "emailsob",
            type: "text",
            label: "Email собственника",
            width: "300px",
          },
          {
            id: "cashsob",
            type: "number_16",
            label: "Номер банковской карты собственника",
            width: "300px",
          },
          {
            id: "FIOArendtr",
            type: "text",
            label: "ФИО арендатора",
            width: "300px",
          },
          {
            id: "paspardtr",
            type: "passport",
            label: "Паспорт арендатора",
            width: "300px",
          },
          {
            id: "adrarendtr",
            type: "address",
            label: "Адрес регистрации арендатора",
            width: "300px",
          },
          {
            id: "drarnd",
            type: "date",
            label: "Дата рождения арендатора",
            width: "300px",
          },
          {
            id: "telardtr",
            type: "number_10",
            label: "Номер телефона арендатора",
            width: "300px",
          },
          {
            id: "emailardtr",
            type: "text",
            label: "Email арендатора",
            width: "300px",
          },
          {
            id: "cashardtr",
            type: "number_16",
            label: "Номер банковской карты арендатора",
            width: "300px",
          },
          {
            id: "FIOjilecs",
            type: "dynamic",
            label: "Добавить проживающего",
            width: "300px",
            innerFields: [
              {
                id: "fiozhilec",
                type: "text",
                label: "ФИО проживающего",
              },
            ],
          },
        ],
      },
      {
        block: "Укажите сведения о жилом помещении:",
        data: [
          {
            id: "adres",
            type: "address",
            label: "Адрес объекта",
            width: "450px",
          },
          {
            id: "kadnomer",
            type: "text",
            label: "Кадастровый номер",
            width: "450px",
          },
          {
            id: "kolvokomnat",
            type: "number_2",
            label: "Количество комнат",
            width: "450px",
          },
        ],
      },
      {
        block:
          "Укажите сведения о цене и порядке оплаты, а так же общих условий договора:",
        data: [
          {
            id: "gorod",
            type: "location",
            label: "Город",
            width: "200px",
          },
          {
            id: "dogdata",
            type: "date",
            label: "Дата начала договора",
            width: "200px",
          },
          {
            id: "cost_rub",
            type: "number_7",
            label: "Стоимость аренды (руб.)",
            width: "250px",
          },
          {
            id: "dataplat",
            type: "number_2",
            label: "День ежемесячного платежа",
            width: "200px",
          },
          {
            id: "zalog",
            type: "checkbox",
            label: "Без залога",
            width: "200px",
            innerFields: [
              {
                id: "zalog_rub",
                type: "number_7",
                label: "Укажите размер залога",
              },
            ],
          },
          {
            id: "srokdog_mon",
            type: "number_2",
            label: "Срок действия договора (в мес.)",
            width: "200px",
          },
          {
            id: "srokrastor",
            type: "number_2",
            label: "Срок предупреждения о досрочном расторжении",
            width: "200px",
          },
          {
            id: "add_conditions",
            type: "select-checkboxes",
            label: "Дополнительные условия",
            width: "250px",
            data: [
              { id: "isAnimals", title: "Разрешено проживание с животными" },
              { id: "isSmoking", title: "Разрешено курение в квартире" },
              {
                id: "isaArenda",
                title: "Разрещена сдача квартиры в субаренду",
              },
            ],
          },
          {
            id: "kommunalka_parent",
            type: "select",
            label: "Коммунальные платежи",
            width: "250px",
            selectedId: "Иное (вписать свой вариант)",
            data: [
              {
                title:
                  "Расходы на содержание квартиры и взносы на капитальный ремонт (ЖКУ) - оплачивает Собственник, свет, вода и интернет - Арендатор в соответствии с показаниями приборов учета",
              },
              { title: "Оплачиваются собственником" },
              { title: "Иное (вписать свой вариант)" },
            ],
            innerFields: [
              {
                id: "kommunalka",
                type: "text",
                label: "Впишите свой вариант",
              },
            ],
          },
        ],
      },
      {
        block: "Указать сведения об имуществе:",
        data: [
          {
            id: "imushestvo",
            type: "dynamic",
            label: "Добавить имущество",
            width: "300px",
            innerFields: [
              {
                id: "item",
                type: "text",
                label: "Предмет",
              },
              {
                id: "sost",
                type: "select",
                label: "Состояние",
                data: [
                  { id: 1, title: "Отличное" },
                  { id: 2, title: "Хорошее" },
                  { id: 3, title: "Удовлетвортиельное" },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: 2,
    name: "Договор купли продажи",
    data: [
      {
        id: "FIO",
        type: "text",
        label: "ФИО1",
        width: "400px",
      },
      {
        id: "PASSPORT",
        type: "passport",
        label: "Паспорт",
        width: "160px",
      },
      {
        id: "BIRTHDAY",
        type: "date",
        label: "Дата рождения",
        width: "200px",
      },
      {
        id: "START_DATE",
        type: "date",
        label: "Дата начала договора",
        width: "200px",
      },
      {
        id: "END_DATE",
        type: "date",
        label: "Дата окончания договора",
        width: "200px",
      },
    ],
  },
  {
    id: 3,
    name: "Расписка заемщика",
    data: [
      {
        id: "FIO",
        type: "text",
        label: "ФИО1",
        width: "400px",
      },
      {
        id: "PASSPORT",
        type: "passport",
        label: "Паспорт",
        width: "160px",
      },
      {
        id: "BIRTHDAY",
        type: "date",
        label: "Дата рождения",
        width: "200px",
      },
      {
        id: "START_DATE",
        type: "date",
        label: "Дата начала договора",
        width: "200px",
      },
      {
        id: "END_DATE",
        type: "date",
        label: "Дата окончания договора",
        width: "200px",
      },
    ],
  },
  {
    id: 4,
    name: "Договор займа",
    data: [
      {
        id: "FIO",
        type: "text",
        label: "ФИО1",
        width: "400px",
      },
      {
        id: "PASSPORT",
        type: "passport",
        label: "Паспорт",
        width: "160px",
      },
      {
        id: "BIRTHDAY",
        type: "date",
        label: "Дата рождения",
        width: "200px",
      },
      {
        id: "START_DATE",
        type: "date",
        label: "Дата начала договора",
        width: "200px",
      },
      {
        id: "END_DATE",
        type: "date",
        label: "Дата окончания договора",
        width: "200px",
      },
    ],
  },
  {
    id: 5,
    name: "Договор дарения",
    data: [
      {
        id: "FIO",
        type: "text",
        label: "ФИО1",
        width: "400px",
      },
      {
        id: "PASSPORT",
        type: "passport",
        label: "Паспорт",
        width: "160px",
      },
      {
        id: "BIRTHDAY",
        type: "date",
        label: "Дата рождения",
        width: "200px",
      },
      {
        id: "START_DATE",
        type: "date",
        label: "Дата начала договора",
        width: "200px",
      },
      {
        id: "END_DATE",
        type: "date",
        label: "Дата окончания договора",
        width: "200px",
      },
    ],
  },
  {
    id: 6,
    name: "Договор подряда",
    data: [
      {
        id: "PASSPORT",
        type: "passport",
        label: "Паспорт",
        width: "160px",
      },
      {
        id: "BIRTHDAY",
        type: "date",
        label: "Дата рождения",
        width: "200px",
      },
      {
        id: "START_DATE",
        type: "date",
        label: "Дата начала договора",
        width: "200px",
      },
      {
        id: "END_DATE",
        type: "date",
        label: "Дата окончания договора",
        width: "200px",
      },
    ],
  },
  {
    id: 7,
    name: "Договор поручительства",
    data: [
      {
        id: "FIO",
        type: "text",
        label: "ФИО1",
        width: "400px",
      },
      {
        id: "PASSPORT",
        type: "passport",
        label: "Паспорт",
        width: "160px",
      },
      {
        id: "BIRTHDAY",
        type: "date",
        label: "Дата рождения",
        width: "200px",
      },
      {
        id: "START_DATE",
        type: "date",
        label: "Дата начала договора",
        width: "200px",
      },
      {
        id: "END_DATE",
        type: "date",
        label: "Дата окончания договора",
        width: "200px",
      },
    ],
  },
];

import axios from "axios";

const apiUrl = process.env.REACT_APP_PAYMENT_API;

const checkPaymentStatus = async (setData, orderId) => {
  try {
    const response = await axios.get(`${apiUrl}/check-payment-status`, {
      params: { orderId: orderId },
    }); // Ваш API для проверки статуса
    setData (response.data);
  } catch (error) {
    console.error("Ошибка при проверке статуса платежа:", error);
  }
};

export default checkPaymentStatus;

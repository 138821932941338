import React, { useState } from "react";
import {
  AppBar,
  Box,
  Container,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import styles from "./ConHeader.module.css";
import * as VKID from "@vkid/sdk";

const ConHeader = ({ theme }) => {
  const [isLogin, SetIsLogin] = useState(false);
  const handleClick = () => {
    VKID.Config.init({
      app: 52470936, // ID вашего приложения ВКонтакте
      redirectUrl: "https://contractors.moscow/logon", // URL для редиректа после авторизации
      scope: "email phone",
    });

    const floatingOneTap = new VKID.FloatingOneTap();

    floatingOneTap.render({
      appName: "VK ID SDK Demo",
    });

    /*  VKID.Auth.login().catch((error) => {
      console.error("Ошибка при аутентификации через ВКонтакте:", error);
    }); */
  };

  return (
    <>
      <AppBar className={styles.appbar}>
        <Container>
          <Toolbar className={styles.toolbar}>
            <Typography variant="h5" className={styles.typography_h5}>
              Создавать договора - это просто!
            </Typography>
            <Box className={styles.box}>
              <IconButton onClick={handleClick}>
                <AccountCircleIcon className={styles.icon_button} />
              </IconButton>
              <Typography className={styles.typography}>
                Личный кабинет
              </Typography>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
};

export default ConHeader;

import { TextField } from "@mui/material";

const DateField = ({
  id,
  label,
  typeInput,
  value,
  error,
  setValue,
  setError,
}) => {
  const handleChange = (e) => {
    const value = e.target.value;
    setValue((prevValues) => ({
      ...prevValues,
      [id]: value,
    }));
    setError((prevErrors) => ({
      ...prevErrors,
      [id]: value === "",
    }));
  };

  return (
    <TextField
      label={label}
      type={typeInput}
      InputLabelProps={{
        shrink: true,
      }}
      variant="outlined"
      value={value[id] || ""}
      onChange={handleChange}
      error={!!error[id]}
      helperText={!!error[id] ? "Заполните поле" : ""}
      fullWidth
    />
  );
};

export default DateField;

import React from "react";
import { Container, Typography, Paper, Box } from "@mui/material";
import styles from "./ConPaper.module.css";

const ConPaper = ({ theme }) => {
  return (
    <Paper
      className={styles.paper}
      sx={{ backgroundImage: "url(/images/test.jpg)" }}
    >
      <Container>
        <Box className={styles.box} />
        <Box className={styles.boxIn}>
          <Typography
            component="h1"
            variant="h3"
            className={styles.typography_1}
            gutterBottom
          >
            Конструктор договоров
          </Typography>
          <Typography variant="h5" className={styles.typography_2}>
            Выберите тип договора, внесите необходимые данные и скачайте готовый
            договор
          </Typography>
        </Box>
      </Container>
    </Paper>
  );
};

export default ConPaper;

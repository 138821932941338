import { TextField, CircularProgress, Box } from "@mui/material";

const TextFieldAll = ({
  id,
  label,
  value,
  error,
  loadingInput,
  setValue,
  setError,
}) => {
  const handleChange = (e) => {
    const value = e.target.value;
    setValue((prevValues) => ({
      ...prevValues,
      [id]: value,
    }));
    setError((prevErrors) => ({
      ...prevErrors,
      [id]: value === "",
    }));
  };

  return (
    <Box position="relative">
      {loadingInput && (
        <CircularProgress
          size={24}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: "-12px",
            marginLeft: "-12px",
          }}
        />
      )}
      <TextField
        key={id}
        label={label}
        type="text"
        variant="outlined"
        value={value[id] || ""}
        onChange={handleChange}
        error={!!error[id]}
        helperText={!!error[id] ? "Заполните поле" : ""}
        fullWidth
      />
    </Box>
  );
};

export default TextFieldAll;

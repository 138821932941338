import React, { useState } from "react";
import { Box, FormControl, FormLabel } from "@mui/material";
import ConFieldInput from "../ConFieldInput/СonFieldInput.js";
import CreateContract from "../CreateContract/CreateContract.js";
import styles from "./СonDataInput.module.css";
import ModalDialog from "../ModalDialog/ModalDialog.js";

const ConDataInput = ({
  selectedContractData,
  inputValues,
  setInputValues,
  valuesError,
  setValuesError,
  handleSubmitValues,
  loading
}) => {
  const [loadingInput, setLoadingInput] = useState(false);

  try {
    return (
      <>
        {selectedContractData.data.map((fields) => (
          <FormControl
            key={fields.block}
            component="fieldset"
            className={styles.form_control}
          >
            <FormLabel component="legend" className={styles.form_label}>
              {fields.block}
            </FormLabel>
            {fields.data.map((field) => (
              <Box key={`Box_${field.id}`} className={styles.box}>
                <ConFieldInput
                  key={`ConTextInput_${field.id}`}
                  id={field.id}
                  label={field.label}
                  label_checkbox={field.label_checkbox}
                  typeInput={field.type}
                  typeText={field.type_text}
                  valueWidth={field.width}
                  data={field.data}
                  selectedId={field.selectedId}
                  innerFields={field.innerFields}
                  value={inputValues}
                  setValue={setInputValues}
                  error={valuesError}
                  setError={setValuesError}
                  loadingInput={loadingInput}
                  setLoadingInput={setLoadingInput}
                />
              </Box>
            ))}
          </FormControl>
        ))}
        ;
        <CreateContract handleSubmitValues={handleSubmitValues} loading={loading} />
      </>
    );
  } catch (error) {
    ModalDialog ("Ошибка", error.message, "error");
  }
};

export default ConDataInput;

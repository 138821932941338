import { TextField } from "@mui/material";
import ReactInputMask from "react-input-mask";

const Passport = ({
  id,
  typeInput,
  value,
  error,
  label,
  setValue,
  setError,
}) => {
  const handleChange = (e) => {
    const value = e.target.value.replace(/_/g, "");
    setValue((prevValues) => ({
      ...prevValues,
      [id]: value,
    }));
    setError((prevErrors) => ({
      ...prevErrors,
      [id]: value === "",
    }));
  };

  return (
    <ReactInputMask
      mask="9999 999999" // Шаблон для паспорта с пробелом
      maskChar="_" // Подчеркивание для пустых символов
      value={value[id] || ""}
      onChange={handleChange}
      error={!!error[id]}
      helperText={!!error[id] ? "Заполните поле" : ""}
      fullWidth
    >
      {(inputProps) => (
        <TextField
          {...inputProps}
          label={label}
          variant="outlined"
          placeholder="____ _______" // Плейсхолдер для начального состояния
        />
      )}
    </ReactInputMask>
  );
};

export default Passport;

import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  TextField,
} from "@mui/material";

const SelectField = ({
  id,
  label,
  value,
  error,
  data,
  selectedId,
  setValue,
  setError,
  innerFields,
}) => {
  const handleChange = (id, event) => {
    const value = event.target.value;
    if (selectedId !== value) {
      innerFields.forEach((field) => {
        setValue((prevValues) => ({
          ...prevValues,
          [id]: value,
          [field.id]: value,
        }));
        setError((prevErrors) => ({
          ...prevErrors,
          [id]: value === "",
          [field.id]: value === "",
        }));
      });
    } else {
      innerFields.forEach((field) => {
        setValue((prevValues) => ({
          ...prevValues,
          [id]: value,
          [field.id]: "",
        }));
        setError((prevErrors) => ({
          ...prevErrors,
          [id]: value === "",
          [field.id]: value === "",
        }));
      });
    }
  };

  return (
    <FormControl fullWidth>
      <InputLabel>{label}</InputLabel>
      <Select
        label={label}
        value={value[id] || ""}
        onChange={(event) => handleChange(id, event)}
        error={!!error[id]}
        fullWidth
      >
        {data.map((select) => (
          <MenuItem key={select.title} value={select.title}>
            {select.title}
          </MenuItem>
        ))}
      </Select>
      {value[id] === selectedId &&
        innerFields.map((fields) => (
          <Box key={`Box_${fields.id}`}sx={{ paddingTop: "20px" }}>
            <TextField
              key={fields.id}
              label={fields.label}
              type={fields.type}
              variant="outlined"
              value={value[fields.id] || ""}
              onChange={(event) => handleChange(fields.id, event)}
              error={!!error[fields.id]}
              helperText={!!error[fields.id] ? "Заполните поле" : ""}
              fullWidth
            />
          </Box>
        ))}
    </FormControl>
  );
};

export default SelectField;

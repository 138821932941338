import React, { useEffect, useState } from "react";
import checkPaymentStatus from "../service/checkPaymentStatus";
import { Box, Link, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import * as VKID from "@vkid/sdk";

const Logon = () => {
  const [data, setData] = useState({});
  const [token, setToken] = useState();
  const navigate = useNavigate();

  VKID.Config.init({
    app: 52470936, // ID вашего приложения ВКонтакте
    redirectUrl: "https://contractors.moscow/logon",
    scope: 'email phone'
  });

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const code = params.get("code");
    const deviceId = params.get("device_id");
    console.log(code, deviceId);
    VKID.Auth.exchangeCode(code, deviceId)
      .then((response) => {
        console.log("Token Response:", response);
        setToken(response.access_token); // Сохраняем токен
        VKID.Auth.userInfo(response.access_token)
          .then((response) => {
            console.log(response);
          })
          .catch((err) => {
            console.error(
              "Ошибка ошибка получения информации о пользователе:",
              err
            );
          });
      })
      .catch((err) => {
        console.error("Ошибка обмена кода на токен:", err);
      });
  }, []);

  const handleReturnHome = () => {
    setData({});
    navigate("/");
  };

  return <p>Токен: {token} </p>;
};

export default Logon;
